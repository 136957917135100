<template>
  <div id="token">
    <div class="f_left token_left">
      <div class="left_nav">
        <ul class='f_left'>
          <li class='f_left'>{{$t('lang.tokenItems')+`(${sourceList.length})`}}</li>
        </ul>
        <el-button
          class="f_left excelBtn"
          type="primary"
          @click="uploadExeclAll()">
          <i class="iconfont icon-export"></i>{{$t('lang.uploadExcelAll')}}</el-button>
        <i
          class="f_right create_sign el-icon-plus"
          @click='showHandleBox("createBox", "create")'></i>
      </div>
      <div class="token_mob">
        <div class="token_mob_left">
          <div class="token_name">{{$t('lang.tokenName')}}:</div>
          <div class="select-box f_left">
            <el-select
              v-model="selectToken"
              @change='changeSort'>
              <el-option
                v-for="item in sourceList"
                :key="item.name"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="token_mob_right">
          <button
            class="btn_send"
            @click='showHandleBox("createBox", "create")'>
            <i
              data-v-7a732f0e=""
              class="create_sign el-icon-plus"></i>{{$t('lang.createToken')}}</button>
        </div>
      </div>
      <div
        class="device_list"
        style='height:85%;'
        v-if='sourceList.length>0'>
        <el-scrollbar style="height:100%;width:100%">
          <div
            class='device_list_item cursor pr'
            :class='{expireColor:item.expireFlag,active:index==selectedRecIndex,notification:notificationArr[index]}'
            v-for='(item, index) in sourceList'
            :key='index'
            @click='switchReceiver(index, item.code, item.tvuccUrl, item.expireFlag, item.shareFlag)'>
            <p class='item_name ellipsis'>
              <span class='ellipsis f_left'>{{item.name}}</span>
              <span
                class='f_right shared_by ellipsis'
                v-if='item.shareFlag'>{{`${$t('lang.shareBy')}`}}
                <i>{{item.fromShareUser}}</i>
              </span>
            </p>
            <p class='clearfix item_info ellipsis'>
              <span class='f_left'>{{$t('lang.expireTime')}} {{convertTimeFormat(item.expireTime)}}</span>
              <span class='f_right'>{{$t('lang.receivers')}}:{{item.rnum}}</span>
            </p>
            <i class='icon_noti'></i>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div
      class="f_left token_right"
      v-if='sourceList.length!=0'>
      <div class="f_left receiver_info">
        <div class="promp">{{$t('lang.qrPromp')}}</div>
        <div class="content">
          <div class="qr_code clearfix">
            <div class='qr_code_box clearfix'>
              <div class="code_box f_left">
                <div class="qrcode_box pr">
                  <div id="qrcode"></div>
                  <div
                    class="overdur"
                    v-if='isShow.overdur'>
                    <i class="iconfont">&#xe991;</i>
                    <p>{{$t('lang.tokenExpired')}}</p>
                  </div>
                </div>
                <p>{{sourceList[selectedRecIndex] ? sourceList[selectedRecIndex].code : ''}}</p>
              </div>
              <div class="receiver_detail f_left">
                <h4 class='ellipsis'>{{sourceList[selectedRecIndex].name}}</h4>
                <p
                  class='time'
                  :class='{gray:isShow.overdur}'>
                  <span>{{$t('lang.from')}}:</span>
                  <span class='f_right'>{{convertTimeFormat(sourceList[selectedRecIndex].createTime)}}</span>
                </p>
                <p
                  class='time'
                  :class='{gray:isShow.overdur}'>
                  <span>{{$t('lang.to')}}:</span>
                  <span class='f_right'>{{convertTimeFormat(sourceList[selectedRecIndex].expireTime)}}</span>
                </p>
                <el-button
                  type="primary"
                  class="btn_send"
                  :class='{gray:isShow.overdur}'
                  :loading="loading"
                  @click='showHandleBox("sendMsg")'>{{$t('lang.snedCodeToEmail')}}</el-button>
                <div
                  class="btn_icon clearfix"
                  v-loading="loading"
                  v-if='!sourceList[selectedRecIndex].shareFlag'>
                  <p
                    class='f_left pointer share_btn'
                    :title='$t("lang.TShare")'
                    :class='{"gray":sourceList[selectedRecIndex].expireFlag}'
                    @click='openShareBox'>
                    <el-button
                      v-if='role==3'
                      type="primary"
                      class="operation_btn f_left">
                      <i
                        class="el-icon-share"
                        :class='{"gray":sourceList[selectedRecIndex].expireFlag}'></i>
                    </el-button>
                    <span
                      class='share_num f_left'
                      v-if='role==3&&sourceList[selectedRecIndex].shareNum'>{{`${sourceList[selectedRecIndex].shareNum} ${$t('lang.members')}`}}</span>
                  </p>
                  <el-button
                    type="primary"
                    class="operation_btn f_left pointer"
                    :title='$t("lang.edit")'
                    @click='showHandleBox("createBox", "edit")'>
                    <i class='iconfont icon-bianji'>&#xe721;</i>
                  </el-button>
                  <el-button
                    type="primary"
                    class="operation_btn f_left pointer"
                    :title='$t("lang.delete")'
                    v-repeat-click='{event:deleteToken}'>
                    <i class='iconfont delete'>&#xe605;</i>
                  </el-button>
                </div>
                <div
                  v-else
                  class='share_by_father'
                  @click.stop='()=>{}'>
                  <p class='ellipsis'>{{$t('lang.shareBy')}}
                    <span>{{sourceList[selectedRecIndex].fromShareUser}}</span>
                  </p>
                  <p>At {{sourceList[selectedRecIndex].shareTime | getDateTime}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="receiver_list">
            <div>
              <h4>{{$t('lang.receiverList')}}({{receiverList.length}})</h4>
              <div class="receiverListscroll">
                <p
                  v-for='(item, index) in receiverList'
                  :key='index'>
                  <span class='f_left ellipsis'>{{item.peerId}}</span>
                  <span class='f_left ellipsis'>/</span>
                  <span class='f_left ellipsis'>{{item.name}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="f_left device_list_box">
        <div class="title clearfix">
          <div class="f_left device_title">{{$t('lang.deviceList')}}({{anyWhereList.length}})</div>
          <el-button
            class="f_left excelBtn"
            type="primary"
            @click="uploadExecl()">
            <i class="iconfont icon-export"></i>{{$t('lang.uploadExcel')}}</el-button>
        </div>
        <div class="title clearfix">
          <div class="f_left status_box">
            <el-select
              v-model="statusValue"
              @change='chooseStatus'>
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div
            class="btns f_left"
            style="margin-top: 7px">
            <button
              class="f_left btn accept"
              v-show='isShow.btn&&!sourceList[selectedRecIndex].expireFlag&&isShow.accept'
              v-repeat-click='{event:tokenConfirm,params:["Accept"]}'>{{$t('lang.accept')}}</button>
            <button
              class="f_left btn deny"
              v-show='isShow.btn&&!sourceList[selectedRecIndex].expireFlag&&isShow.accept'
              v-repeat-click='{event:tokenConfirm,params:["Deny"]}'>{{$t('lang.deny')}}</button>
            <button
              class="f_left btn delete"
              v-show='isShow.btn'
              v-repeat-click='{event:tokenConfirm,params:["Delete"]}'>{{$t('lang.delete')}}</button>
            <button
              class="f_left btn set_expired"
              v-show='isShow.btn&&!sourceList[selectedRecIndex].expireFlag&&isShow.accept'
              v-repeat-click='{event:tokenConfirm,params:["Expire"]}'>{{$t('lang.setExpired')}}</button>
          </div>
          <div class="f_right page">
            <Pagination
              v-if='total>0'
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="deviceParam.currentPageNum"
              :pageSizes="[10, 20, 50, 100]"
              :pageSize="deviceParam.everyPageNum"
              :total="total"
              layout="prev, pageF, next, pageL, sizes, nowSize, jumper" />
          </div>
        </div>
        <div class="list_item_box">
          <div class="item_title clearfix">
            <el-row>
              <el-col :span="7">
                <div
                  class="all_device pointer"
                  @click='handleAll'
                  :class='{active: allDevice}'>
                  <i class='icon_select f_left'></i>
                  {{$t('lang.device')}}
                </div>
              </el-col>
              <el-col :span="3">
                <div class="">{{$t('lang.status')}}</div>
              </el-col>
              <el-col :span="3">
                <div class="">{{$t('lang.phone')}}</div>
              </el-col>
              <el-col :span="3">
                <div class="">{{$t('lang.introduction')}}</div>
              </el-col>
              <el-col :span="5">
                <div class="">{{$t('lang.joinTime')}}</div>
              </el-col>
              <el-col :span="3">
                <div class="">{{$t('lang.operation')}}</div>
              </el-col>
            </el-row>
          </div>
          <div class="item_content">
            <ul>
              <li
                v-for='(item, index) in anyWhereList'
                :class='{gray:item.status==4||sourceList[selectedRecIndex].expireFlag}'
                :key='index'
                @click='handleItem(index, item.status)'>
                <el-row>
                  <el-col :span="7">
                    <div
                      class="pointer type ellipsis"
                      :class='{active: activeDevice[index]}'>
                      <i class='icon_select f_left'></i>
                      {{item.peerId}} / {{item.name}}
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div :class='{gray:item.status==2,pending:item.status==1,green:item.status==3}'>{{$t(`lang.${formatStatus(item.status)}`)}}</div>
                  </el-col>
                  <el-col :span="3">
                    <div class="ellipsis">{{formatVal(item.phone)}}</div>
                  </el-col>
                  <el-col :span="3">
                    <div class="ellipsis">{{formatVal(item.description)}}</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="ellipsis">{{formatJoinTime(item.updateTime, item.status)}}</div>
                  </el-col>
                  <el-col
                    :span="3"
                    v-if='item.status==3&&item.expireFlag==0'>
                    <div
                      class="edit_device"
                      @click='editDevice(item)'>{{$t('lang.editDeviceBtn')}}</div>
                  </el-col>
                </el-row>
              </li>
              <li v-if='anyWhereList.length==0'>
                <el-row>
                  <el-col :span="7">- - -</el-col>
                  <el-col :span="3">- - -</el-col>
                  <el-col :span="3">- - -</el-col>
                  <el-col :span="4">- - -</el-col>
                  <el-col :span="7">- - -</el-col>
                </el-row>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="f_left no_token"
      v-if='isShow.createBtn'>
      <div>
        <p>{{$t('lang.noTokenPrompt')}}</p>
        <button @click='showHandleBox("createBox", "create")'>{{$t('lang.createToken')}}</button>
      </div>
    </div>
    <div
      class="create_box"
      v-show='isShow.createBox || isShow.sendMsg'>
      <div class="mask"></div>
      <create-token
        @hideHandleBox='hideHandleBox'
        v-if='isShow.createBox'
        :editItem='sourceList[selectedRecIndex] || {}'
        :type='popUpType'></create-token>
      <send-msg
        v-show='isShow.sendMsg'
        @hideHandleBox='hideHandleBox'
        :selectedItem='sourceList[selectedRecIndex] || {}'
        :isShowQrcode='isShow.qrcode'></send-msg>
    </div>
    <edit-device
      :dialogVisible.sync='dialogVisible'
      :editDevice='editDeviceObj'
      @updateList='getAnyWhereList'></edit-device>
    <share-token
      :shareVisible='shareVisible'
      :code='sourceList[selectedRecIndex].code'
      v-if='shareVisible'
      @closeShareBox='closeBox'></share-token>
  </div>
</template>
<script>
import CreateToken from './CreateToken'
import SendMsg from './SendMsg'
import ShareToken from './ShareToken'
import Outil from '@/assets/js/utils.js'
import QRCode from 'qrcodejs2'
import Pagination from '@/components/Pagination'
import editDevice from './EditDevice'
import { tokenAnalyze } from '@/assets/js/googleAnalyze/token.js'
import { mapState } from 'vuex'
import { deleteToken, handleAnywhere, clearNotify, findToken } from '@/service/token'

export default {
  components: {
    CreateToken,
    SendMsg,
    Pagination,
    editDevice,
    ShareToken
  },
  data () {
    return {
      selectToken: '',
      statusValue: '0',
      statusList: [
        {
          value: '0',
          label: 'lang.all'
        },
        {
          value: '1',
          label: 'lang.pending'
        },
        {
          value: '3',
          label: 'lang.accepted'
        },
        {
          value: '2',
          label: 'lang.denied'
        }
      ],
      dialogVisible: false,
      shareVisible: false,
      loading: true,
      role: null,
      editDeviceObj: {
        name: '',
        peerId: ''
      },
      sourceList: [{}],
      notificationArr: [],
      active: {
        grid: false
      },
      sourcePar: {
        type: ''
      },
      receiverPar: {
        code: '',
        currentPageNum: 1,
        everyPageNum: 10,
        status: 0
      },
      isShow: {
        createBox: false,
        sendMsg: false,
        qrcode: true,
        btn: false,
        overdur: 0,
        accept: true,
        createBtn: false
      },
      qrContent: '',
      selectedRecIndex: 0,
      qrcode: {},
      selectedStatus: 'status',
      deviceParam: {
        code: '',
        currentPageNum: 1,
        everyPageNum: 10,
        status: 0,
        shareFlag: null
      },
      anyWhereList: [],
      allDevice: false,
      activeDevice: [], // 单个item是否选中
      confirmPar: {
        confirm: '',
        willAddRIds: '',
        code: '',
        shareFlag: null
      },
      receiverList: [],
      total: 0,
      popUpType: 'create',
      lastCode: '',
      deviceTotalNum: 0,
      timer: null,
      selectFilter: '',
      showTokenList: true,
      qCode: null
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      CommonState: state => state.common,
      pageRequest: (state) => state.websocket.pageRequest
    })
  },
  filters: {
    getDateTime (time) {
      time = typeof time == 'object' ? time : new Date(time)
      var year = time.getFullYear()
      var month = Outil.timeFormat(time.getMonth() + 1)
      var day = Outil.timeFormat(time.getDate())
      var hour = Outil.timeFormat(time.getHours())
      var min = Outil.timeFormat(time.getMinutes())
      return `${hour}:${min} ${month}/${day}/${year}`
    }
  },
  created () {
    this.role = this.CommonState.baseUserInfo.role
    window.addEventListener('resize', this.handleResize)
  },
  mounted () {
    this.getTokenList(1)
    this.getAlertData(1)
  },
  methods: {
    // 窗口大小调整
    handleResize () {
      if ($('.token_mob .select-box .el-input.el-input--suffix.is-focus').length > 0) {
        $('.select-box input').click()
      }
    },
    // 页码处理
    handleSizeChange (val) {
      this.deviceParam.everyPageNum = val
      this.deviceParam.currentPageNum = 1
      this.getAnyWhereList(true, 1)
    },
    handleCurrentChange (val) {
      this.deviceParam.currentPageNum = val
      this.getAnyWhereList(true, 1)
    },
    // 格式化time
    formatJoinTime (val, status) {
      if (status == 1 || status == 2 || status == 4) return '---'
      else return this.convertTimeFormat(val)
    },
    // 格式化status
    formatStatus (status) {
      switch (status) {
        case 1:
          return 'tokenPending'
        case 2:
          return 'rejected'
        case 3:
          return 'accepted'
        default:
          return 'expired'
      }
    },
    // 格式化
    formatVal (val) {
      if (val == '' || val == null) return '---'
      else return val
    },
    // 显示创建框
    showHandleBox (key, type) {
      if (type == 'create') {
        tokenAnalyze('tokenCreateToken', '/tkm')
      } else if (type == 'edit') {
        tokenAnalyze('tokenEditToken', '/tkm')
      } else if (type == undefined) {
        tokenAnalyze('tokenSendEmail', '/tkm')
      }
      if (key == 'sendMsg' && this.isShow.overdur) return
      this.popUpType = type || ''
      this.isShow[key] = true
    },
    uploadExeclAll () {
      let timeZone = -new Date().getTimezoneOffset() / 60
      if (timeZone > 0) {
        timeZone = 'GMT+' + timeZone
      } else {
        timeZone = 'GMT' + timeZone
      }
      window.location.href = `/tokenDevice/deriveExcelByToken?timeZone=${encodeURI(timeZone).replace(/\+/g, '%2B')}`
    },
    // 隐藏弹出窗框
    hideHandleBox (key, getList) {
      this.isShow[key] = false
      if (getList) this.getTokenList(1)
    },

    // 获取token列表
    getTokenList (time) {
      findToken().then(res => {
        let data = res.data.errorInfo
        this.sourceList = data
        this.updateIndex(data)
        this.isShow.createBtn = !this.sourceList.length
        this.loading = false
        if (data.length == 0) return
        // 获取device列表
        this.deviceParam.code = this.selectedRecIndex ? this.sourceList[this.selectedRecIndex].code : this.sourceList[0].code
        this.deviceParam.shareFlag = this.selectedRecIndex ? this.sourceList[this.selectedRecIndex].shareFlag : this.sourceList[0].shareFlag
        this.isShow.overdur = this.selectedRecIndex ? this.sourceList[this.selectedRecIndex].expireFlag : this.sourceList[0].expireFlag
        this.selectToken = this.sourceList[this.selectedRecIndex].code
        this.isShow.qrcode = false
        $('#qrcode').html('')
        $('#sent-qrbox').html('')
        this.updateQR(this.sourceList[this.selectedRecIndex].tvuccUrl)
        if (time) this.getAnyWhereList(false, 1)
      }).catch(() => {})
    },
    // 判断选中的index是否与返回列表的index相同
    updateIndex (data) {
      data.map((v, i) => {
        if (this.lastCode == v.code) {
          this.selectedRecIndex = i
          this.selectToken = this.sourceList[this.selectedRecIndex].code
        }
      })
    },
    // 生成二维码
    createQrcode (ele) {
      if (!document.getElementById(ele)) return
      this.qCode = new QRCode(ele, {
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
        text: this.qrContent // `###${code}${tokenSuffix}`   tokenSuffix后台传值
      })
    },
    // update二维码       qrContent不全 tokenSuffix需要后台传
    updateQR (tvuccUrl) {
      this.isShow.qrcode = true
      this.qrContent = `####${this.sourceList[this.selectedRecIndex].code}${tvuccUrl}`
      this.$nextTick(() => {
        this.createQrcode('qrcode')
        this.createQrcode('sent-qrbox')
      })
    },
    // 格式化时间
    convertTimeFormat (str) {
      if (str == '' || str == null) return '---'
      str = +str
      var oDate = new Date(str)
      var oYear = oDate.getFullYear()
      var oMonth = oDate.getMonth() + 1
      var oDay = oDate.getDate()
      var oHour = oDate.getHours()
      var oMin = oDate.getMinutes()
      var oTiem = oYear + '/' + this.formatTime(oMonth) + '/' + this.formatTime(oDay) + ' ' + this.formatTime(oHour) + ':' + this.formatTime(oMin)
      return oTiem
    },
    formatTime (num) {
      if (parseInt(num) < 10) {
        num = '0' + num
      }
      return num
    },
    // 分享
    openShareBox () {
      if (this.sourceList[this.selectedRecIndex].expireFlag) return // 过期的token不能分享
      this.shareVisible = true
    },
    // 删除token
    deleteToken () {
      tokenAnalyze('tokenDeleteToken', '/tkm')
      this.$confirm(this.$t('lang.ensureDelete'), '', {
        confirmButtonText: this.$t('lang.Ok'),
        cancelButtonText: this.$t('lang.cancel')
      }).then(() => {
        deleteToken(this.sourceList[this.selectedRecIndex].code).then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.selectedRecIndex = 0
            this.getTokenList(1)
          }
        }).catch(() => {})
      }).catch(() => {})
    },
    // 切换receiver
    switchReceiver (index, code, tvuccUrl, expireFlag, shareFlag) {
      tokenAnalyze('tokenItemsList', '/tkm')
      this.deviceParam.currentPageNum = 1
      this.selectedStatus = 'status'
      this.selectedRecIndex = index
      this.selectToken = this.sourceList[this.selectedRecIndex].code
      this.lastCode = this.sourceList[index].code
      this.isShow.overdur = expireFlag
      this.isShow.qrcode = false
      $('#qrcode').html('')
      $('#sent-qrbox').html('')
      this.updateQR(tvuccUrl)
      this.deviceParam.code = code
      this.deviceParam.shareFlag = shareFlag
      this.getAnyWhereList(true, 1)
      // 清除消息提示
      this.clearNotification(code, index)
    },
    // 改变状态
    chooseStatus () {
      switch (Number(this.statusValue)) {
        case 0:
          this.selectedStatus = 'status'
          break
        case 1:
          this.selectedStatus = 'pending'
          break
        case 2:
          this.selectedStatus = 'denied'
          break
        case 3:
          this.selectedStatus = 'accepted'
          break
      }
      this.deviceParam.status = this.statusValue
      this.getAnyWhereList(true, 1)
    },
    // 获取配对后的anywhere
    getAnyWhereList (isSwitchToken, time) {
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'tokenFindDevice') // 停止websocket
      }
      if (!this.deviceParam.code) return
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'tokenFindDevice',
          module: 'tokenFindDevice',
          data: this.deviceParam
        },
        time: time || 10000,
        key: 'tokenFindDevice',
        success: res => {
          let data = res.result.result
          this.anyWhereList = []
          if (data.tPageBar) {
            this.total = data.tPageBar.totalNum
            this.anyWhereList = data.tPageBar.resultList
            this.deviceTotalNum = data.tPageBar.totalNum
          } else {
            this.total = 0
          }
          this.receiverList = data.rList
          // 如果是切换token||切换页码等，刷新device列表中的选中及其他处理
          if (isSwitchToken) {
            this.activeDevice = []
            this.allDevice = false
            this.isShow.btn = false
          }
          time && this.$nextTick(() => {
            this.getAnyWhereList(false)
          })
        }
      })
    },
    // 点击全部
    handleAll () {
      tokenAnalyze('tokenAllSelectedDevice', '/tkm')
      if (this.anyWhereList.length == 0) return // 如果device列表为空 return
      // 判断是否能够点击全部
      let status = []
      this.anyWhereList.map(v => {
        status.push(v.status)
      })
      this.allDevice = !this.allDevice
      this.activeDevice = []
      this.anyWhereList.map(v => {
        this.activeDevice.push(this.allDevice)
      })
      // 如果有状态为过期，不显示删除以外的其他按钮
      this.setIsShowAccept()
      // 是否显示btn
      this.isShow.btn = this.activeDevice.includes(true)
    },
    // 点击单个
    handleItem (index, status) {
      this.activeDevice[index] = !this.activeDevice[index]
      let arr = this.activeDevice
      this.activeDevice = []
      Array.prototype.push.apply(this.activeDevice, arr)
      // 关联全部
      let flag = true
      this.activeDevice.map(v => {
        if (!v) flag = false
      })
      if (flag && this.activeDevice.length == this.anyWhereList.length) this.allDevice = true
      else this.allDevice = false
      // 如果有状态为过期，不显示删除以外的其他按钮
      this.setIsShowAccept()
      // 是否显示btn
      this.isShow.btn = this.activeDevice.includes(true)
    },
    // 设置是否显示accept等按钮
    setIsShowAccept () {
      this.isShow.accept = true
      this.anyWhereList.map((v, i) => {
        if (v.status == 4 && this.activeDevice[i]) this.isShow.accept = false
      })
    },
    // 接收到的 token 处理
    tokenConfirm (type) {
      tokenAnalyze(`token${type}Request`, '/tkm')
      this.confirmPar.confirm = type
      this.confirmPar.code = this.deviceParam.code
      this.confirmPar.shareFlag = this.deviceParam.shareFlag
      let arr = []
      this.activeDevice.map((v, i) => {
        if (v && this.anyWhereList[i]) arr.push(this.anyWhereList[i].id)
      })
      this.confirmPar.willAddRIds = arr.join(',')
      handleAnywhere(this.confirmPar)
        .then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.getAnyWhereList(true, 1)
            this.$message({
              message: this.$t('lang.success'),
              type: 'success'
            })
          } else {
            this.$message({
              message: this.$t('lang.operationFailure'),
              type: 'error'
            })
          }
        }).catch(() => {
          this.$message({
            message: this.$t('lang.serverError'),
            type: 'error'
          })
        })
    },
    // notification
    getAlertData (time) {
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'tokenAlert') // 停止websocket
      }
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'tokenAlert',
          module: 'tokenAlert',
          data: {
            atPage: true
          }
        },
        time: time || 10000,
        key: 'tokenAlert',
        success: res => {
          time && this.getAlertData()
          if (res.result.errorCode == '0x0' && res.result.result.errorFlag) {
            this.notificationArr = []
            this.sourceList.map((item, index) => {
              this.notificationArr.push(false)
              res.result.result.errorInfo.map((v, i) => {
                if (item.code == v.code) {
                  if (this.selectedRecIndex != index) {
                    this.notificationArr[index] = true
                  } else {
                    this.clearNotification(item.code)
                  }
                  if (this.selectedRecIndex == index && v.count > this.deviceTotalNum) {

                  }
                }
              })
            })
          }
        }
      })
    },
    // 清除消息提示
    clearNotification (code, index) {
      clearNotify(code).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          if (index == undefined) return
          this.notificationArr[index] = false
          return
        }
        this.$message.error(this.$t('lang.operationFailed'))
      }).catch(() => {})
    },
    changeSort (val) {
      this.sourceList.map((v, i) => {
        if (v.code == val) {
          this.switchReceiver(i, v.code, v.tvuccUrl, v.expireFlag, v.shareFlag)
        }
      })
    },
    // 打开编辑设备名称窗口
    editDevice (editDevice) {
      tokenAnalyze('tokenEditDeviceName', '/tkm')
      this.editDeviceObj.name = editDevice.name
      this.editDeviceObj.peerId = editDevice.peerId
      this.dialogVisible = true
    },
    closeBox (key, value, update) {
      this[key] = value
      if (update) {
        this.getTokenList(1)
      }
    },
    uploadExecl () {
      let lang = window.localStorage.getItem('lang')
      let langApi = 'com'
      if (lang == 'zh') {
        langApi = 'cn'
      } else {
        langApi = 'com'
      }
      let timeZone = -new Date().getTimezoneOffset() / 60
      let code = this.sourceList[this.selectedRecIndex].code
      let usedId = this.sourceList[this.selectedRecIndex].userId
      if (timeZone > 0) {
        timeZone = 'GMT+' + timeZone
      } else {
        timeZone = 'GMT' + timeZone
      }

      if (this.sourceList[this.selectedRecIndex].code) {
        window.location.href = `/tokenDevice/downloadDevice?code=${code}&shareFlag=${this.deviceParam.shareFlag}&timeZone=${encodeURI(timeZone).replace(/\+/g, '%2B')}&userId=${usedId}&language=${langApi}`
      }
    }
  },
  beforeDestroy () {
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', ['tokenFindDevice', 'tokenAlert']) // 停止websocket
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style lang="less" scoped>
.gray {
  color: gray !important;
  cursor: not-allowed;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/*滑块*/
::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
/*滑道*/
::-webkit-scrollbar-track {
  border-radius: 10px;
}
p {
  margin-bottom: 0;
}
#token {
  height: 100%;
  width: 19.2rem;
  .token_left {
    width: 20.5%;
    height: 9rem;
    padding-left: 25px;
    box-shadow: 0 0 0.1rem #000;
    background-color: #444;
  }
  .left_nav {
    width: 100%;
    height: 8.6%;
    color: #dbdbdb;
    border-bottom: 2px solid #666;
    font-size: 24px;
    box-sizing: border-box;
    ul {
      height: 100%;
      padding-top: 10%;
    }
    li {
      margin-right: 37px;
      height: 100%;
      color: rgba(153, 153, 153, 1);
      font-size: 16px;
      font-weight: 700;
    }
    .excelBtn {
      width: 90px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid #33ab4f;
      padding: 0;
      background: none;
      margin-left: 30px;
      font-size: 12px;
      margin-top: 9%;
      .icon-export {
        color: #33ab4f;
        padding-right: 5px;
      }
    }
    li.active {
      border-bottom: 2px solid #33ab4f;
      color: #33ab4f;
    }
    .create_sign {
      font-weight: 700;
      font-size: 28px;
      cursor: pointer;
      padding-top: 9%;
      margin-right: 12px;
    }
  }
  .device_list {
    margin-top: 5%;
    text-align: left;
    .device_list_item {
      height: 60px;
      padding: 9px 8% 5px 10px;
    }
    .device_list_item.active {
      background-color: rgba(57, 170, 80, 0.25);
    }
    .device_list_item.expireColor {
      color: #6d6d6d;
    }
    .device_list_item.active {
      background-color: rgba(57, 170, 80, 0.25);
    }
    .device_list_item.notification {
      .icon_noti {
        position: absolute;
        right: 8%;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #db1a14;
      }
    }
    .item_name {
      font-size: 18px;
      margin-bottom: 4px;
      span:first-child {
        max-width: 70%;
      }
      .shared_by {
        font-size: 12px;
        width: 30%;
        text-align: right;
        i {
          font-style: normal;
          color: #33ab4f;
        }
      }
    }
    .item_info {
      color: #8c8a88;
    }
  }
  .create_box {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
  }
  .token_right {
    width: 79.5%;
    height: 9rem;
    padding: 60px 0 0 45px;
    background: #252525;
    .receiver_info {
      width: 25.3%;
      height: 100%;
      .promp {
        text-align: left;
        color: #6c6c6c;
        float: left;
      }
      .content {
        border-right: 2px solid #333;
        padding-right: 18px;
        padding-top: 52px;
        height: 100%;
      }
      .qr_code {
        margin-top: 12px;
        .qr_code_box {
          padding-bottom: 24px;
          border-bottom: 2px solid #333;
          margin-bottom: 33px;
        }
      }
      .code_box {
        width: 160px;
        color: #33ab4f;
        background-color: #2d2d2d;
        text-align: center;
        p {
          font-size: 28px;
        }
      }
      .qrcode_box {
        .overdur {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: rgb(255, 255, 255);
          width: 75px;
          height: 75px;
          color: #f00;
          opacity: 0.9;
          text-align: center;
          i {
            margin-top: -9px;
            font-size: 52px;
          }
          p {
            margin-top: -15px;
            font-size: 18px;
          }
        }
      }
      .qrcode_box,
      #qrcode {
        width: 160px;
        height: 160px;
        background: #fff;
      }
      #qrcode {
        border: 5px solid rgb(255, 255, 255);
      }
      .receiver_detail {
        text-align: left;
        padding-left: 12px;
        h4 {
          height: 35px;
          width: 180px;
          word-wrap: break-word;
          line-height: 26px;
          overflow: hidden;
        }
        .time {
          font-size: 14px;
          // padding-right: 17px;
          line-height: 22px;
          span:first-child {
            color: #8c8a88;
          }
        }
        .time.gray {
          color: #a1a1a1;
        }
        .btn_send {
          margin-top: 27px;
          width: 180px;
          height: 35px;
          line-height: 35px;
          padding: 0;
          cursor: pointer;
          border: 0;
          border-radius: 5px;
          font-size: 16px;
          color: #fff;
          background-color: #39aa50;
        }
        .btn_send.gray {
          background-color: #3f3f3f;
          color: #5a5a5a;
          cursor: not-allowed;
        }
        .btn_icon {
          margin-top: 15px;
          font-size: 14px;
          padding-right: 30px;
          > div {
            vertical-align: middle;
          }
          .share_btn {
            margin-right: 10px;
          }
          ::v-deep .el-loading-mask {
            z-index: 10;
          }
          .operation_btn {
            padding: 0;
            background: transparent;
            border: none;
            .el-icon-share {
              color: #33ab4f;
            }
          }
          .share_num {
            margin-left: 5px;
            margin-right: 13px;
          }
          span {
            margin-left: 6px;
          }
          i {
            font-size: 18px;
          }
          i.icon-bianji {
            color: #33ab4f;
          }
          i.delete {
            color: #f33c10;
          }
        }
        .share_by_father {
          color: #8c8a88;
          padding-top: 5px;
          p {
            margin-top: 3px;
            max-width: 180px;
            span {
              color: #33ab4f;
            }
          }
        }
      }
      .receiver_list {
        padding: 20px;
        box-sizing: border-box;
        border-radius: 3px;
        color: #a1a1a1;
        max-height: 518px;
        text-align: left;
        background-color: #2d2d2d;
        overflow: auto;
        .receiverListscroll {
          height: 140px;
          overflow: auto;
        }
        h4 {
          font-size: 16px;
          line-height: 35px;
          color: #8c8a88;
          border-bottom: 2px solid #666;
          font-weight: bold;
        }
        p {
          height: 48px;
          line-height: 48px;
          box-sizing: border-box;
          border-bottom: 1px dashed #666;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          span:first-child {
            width: 50%;
          }
          span:nth-child(2) {
            width: 7%;
          }
          span:nth-child(3) {
            width: 42%;
          }
        }
        p:last-child {
          border: none;
        }
      }
    }
  }
  .no_token {
    width: 79.5%;
    height: 100%;
    position: relative;
    > div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      font-size: 16px;
      color: #fff;
    }
    button {
      width: 215px;
      height: 55px;
      border: 0;
      border-radius: 5px;
      font-size: 24px;
      color: #fff;
      background-color: #33ab4f;
      margin-top: 40px;
    }
  }
  .device_list_box {
    width: 73%;
    text-align: left;
    padding-left: 26px;
    .excelBtn {
      width: 120px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid #33ab4f;
      padding: 0;
      background: none;
      margin-left: 30px;
      font-size: 12px;
      .icon-export {
        color: #33ab4f;
        padding-right: 5px;
      }
    }
    .device_title {
      font-size: 16px;
      line-height: 34px;
      font-weight: bold;
    }
    .btn {
      margin-left: 23px;
      padding: 0;
      width: 92px;
      height: 28px;
      line-height: 28px;
      border: 1px solid rgba(51, 171, 79, 1);
      background: rgba(57, 170, 80, 1);
      border-radius: 4px;
      color: #fff;
    }
    .btn.accept:hover {
      background: #3e8f3e;
    }
    .btn.deny {
      background: transparent;
    }
    .btn.deny:hover {
      background: rgba(57, 170, 80, 1);
    }
    .btn.set_expired {
      width: 120px !important;
    }
    .btn.delete,
    .btn.set_expired {
      background: transparent;
      border: 1px solid rgba(68, 68, 68, 1);
    }
    .btn.delete:hover {
      background: #f94345;
      border-color: #f94345;
    }
    .btn.set_expired:hover {
      background: #808080;
    }
    .page {
      height: 40px;
      width: 300px;
    }
    .status_box {
      /*margin-left: 28px;*/
      width: 112px;
      height: 35px;
      line-height: 35px;
      background-color: #444;
      border-radius: 6px;
      .el-dropdown-link {
        display: inline-block;
      }
      ::v-deep .el-dropdown {
        width: 100%;
        text-align: left;
        color: #dbdbdb;
        font-size: 14px;
        text-indent: 10px;
      }
      ::v-deep .el-select input {
        padding-left: 6px;
        padding-right: 20px;
      }
      i {
        position: absolute;
        right: 10%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .list_item_box {
      margin-top: 10px;
      width: 100%;
      .item_title {
        border-bottom: 2px solid #217536;
        line-height: 32px;
        color: #8c8a88;
        text-indent: 8px;
      }
      .icon_select {
        margin-top: 3px;
        width: 22px;
        height: 22px;
        background: url('../../assets/img/select.png');
        background-position: 2px -46px;
      }
      .active .icon_select {
        background-position: 2px -86px;
      }
      .item_content {
        text-indent: 8px;
        max-height: 675px;
        overflow: auto;
        .el-row {
          height: 63px;
          line-height: 63px;
          border-bottom: 1px solid #333;
        }
        li:hover {
          background-color: rgba(57, 170, 80, 0.25);
        }
        li .pending {
          color: #fb8c00;
        }
        li.prohibit {
          cursor: not-allowed;
          div {
            cursor: not-allowed;
          }
        }
        li .gray,
        li.gray {
          color: #999;
        }
        li .green {
          color: #33ab4f;
        }
        .type {
          padding-left: 20px;
        }
        .icon_select {
          margin-top: 18px;
        }
        .edit_device {
          text-decoration: underline;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #666;
    opacity: 0.65;
  }
  .token_mob {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 10px 15px 0px;
    .token_mob_left {
      display: flex;
    }
    button {
      margin-top: 0;
      width: auto;
      height: 27px;
      cursor: pointer;
      border: 0;
      border-radius: 5px;
      font-size: 14px;
      color: #fff;
      background-color: #39aa50;
      margin-bottom: 0px;
      padding: 0 10px;
    }
    .token_name {
      color: #999999;
      font-size: 16px;
      margin-right: 20px;
    }
    .status_box {
      background-color: #444;
      border: 1px solid #666;
      border-radius: 4px;
      font-size: 13px;
      text-align: left;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: none;
      width: 140px;
      padding: 3px 10px;
      .el-dropdown {
        width: 121px;
        color: #fff;
      }
      .el-icon-caret-bottom {
        float: right;
        margin-top: 3px;
      }
    }
    .el-icon-plus {
      display: inline-block !important;
    }
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  #token {
    width: 100%;
    .token_right {
      .receiver_info {
        width: 31.3%;
        height: auto;
      }
      .device_list_box {
        width: 68%;
      }
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {
  #token {
    width: 100%;
    .token_left {
      width: 26.5%;
    }
    .token_right {
      width: 73.5%;
      .receiver_info {
        width: 100%;
        height: auto;
        .qr_code {
          width: 49%;
          float: left;
        }
        .receiver_list {
          width: 49%;
          float: right;
        }
      }
      .device_list_box {
        width: 97%;
      }
    }
  }
}

@media screen and (min-width: 760px) and (max-width: 1279px) {
  #token {
    width: 100%;
    .token_left {
      width: 100%;
      height: auto;
      background: transparent;
      padding-left: 10px;
      padding-right: 10px;
      background-color: #2c2c2c;
      .create_sign {
        display: none;
      }
      .token_mob_left .token_name {
        line-height: 40px;
      }
    }
    .token_right {
      width: 100%;
      padding: 30px 0 0 10px;
      height: auto;
      .receiver_info {
        width: 100%;
        height: auto;
        padding-left: 25px;
        .qr_code {
          width: 49%;
          float: left;
        }
        .receiver_list {
          width: 49%;
          float: right;
          height: auto;
        }
      }
      .device_list_box {
        width: 98%;
        .btn {
          margin-left: 15px;
        }
      }
    }
    .device_list {
      display: none;
    }
    .token_mob {
      display: flex;
    }
  }
}
@media screen and (min-width: 500px) and (max-width: 759px) {
  #token {
    width: 100%;
    .token_left {
      width: 100%;
      height: auto;
      background: transparent;
      padding-left: 10px;
      padding-right: 10px;
      background-color: #2c2c2c;
      .create_sign {
        display: none;
      }
      .token_mob_left .token_name {
        line-height: 40px;
      }
    }
    .token_right {
      width: 100%;
      padding: 30px 0 0 10px;
      height: auto;
      .receiver_info {
        width: 100%;
        height: auto;
        .receiver_detail {
          width: 80%;
          margin-left: 20px;
          .btn_send {
            float: right;
          }
          .btn_icon {
            float: left;
            width: 100%;
            padding-right: 0;
          }
        }
        .qr_code {
          width: 49%;
          float: left;
          .code_box {
            margin: auto;
            float: none;
          }
        }
        .receiver_list {
          width: 49%;
          float: right;
          height: 440px;
          .receiverListscroll {
            height: 335px;
          }
        }
      }
      .device_list_box {
        width: 97%;
        padding-left: 0;
        .device_title {
          font-size: 12px;
        }
        .status_box {
          margin-left: 5px;
          width: 90px;
          margin-right: 5px;
          .el-dropdown {
            text-indent: 3px;
          }
        }
        .f_right {
          height: 40px;
          width: auto;
          float: right;
          .paginationBox {
            margin-top: 3px;
          }
        }
        .btns {
          float: right;
        }
        .btn {
          width: 69px;
          margin-left: 5px;
        }
        > .title {
          position: relative;
          margin-bottom: 50px;
          .status_box {
            position: absolute;
            left: 0;
            top: 45px;
          }
          .page {
            position: absolute;
            right: 0;
            top: 45px;
          }
        }
      }
      .list_item_box {
        font-size: 11px;
        .item_content {
          .type {
            padding-left: 0px;
          }
        }
      }
    }
    .device_list {
      display: none;
    }
    .token_mob {
      display: flex;
    }
  }
}

@media screen and (min-width: 650px) and (max-width: 759px) {
  #token {
    .token_right {
      .receiver_info {
        .receiver_detail {
          width: 55% !important;
          margin-left: 75px !important;
        }
      }
    }
  }
}
</style>
